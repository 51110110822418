.tab {

    &-link {

        &[aria-selected='true'] {
            @apply bg-gray border-b-black;
        }
    }

    &-header {

        .tab--faq & {
            @apply relative;

            &::before {
                content: '';
                @apply absolute bg-white;

                @include media-breakpoint-down(lg) {
                    @apply bottom-0 right-5 left-5;
                    height: 1px;
                }

                @include media-breakpoint-up(lg) {
                    @apply top-0 right-0 h-full;
                    width: 1px;
                }
            }
                    
            .item {

                &-list {
                    @apply flex flex-wrap -m-1;

                    @include media-breakpoint-down(sm) {

                        > div {
                            @apply p-1;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        @apply grid gap-4 -m-0;
                    }
                    

                    &__item {
                        @apply py-3 px-4 lg:py-4 lg:px-6 bg-transparent rounded-rg border-[2px] border-transparent overflow-hidden transition ease-in-out;

                        &:hover {
                            @apply text-secondary;

                            svg {
                                
                                path {
                                    @apply fill-secondary;
                                }
                            }
                        }

                        &[aria-selected="true"] {
                            @apply bg-secondary border-primary text-white;

                            svg {
                                
                                path {
                                    @apply fill-white;
                                }
                            }
                        }

                        svg {
                            @apply h-5;
                        }
                    }
                }
            }
        }
    }

    &-content {

        // .tab--faq & {

        //     .item {

        //         @include media-breakpoint-up(lg) {
        //             @apply mt-12;
        //         }
        //     }
        // }
    }
}