.single {

    &-content {
        
        @include media-breakpoint-up(lg) {
            background-position: 25% 0;
        }

        .content {
            @apply bg-white bg-opacity-60 mx-auto w-[calc(100%-32px)] lg:w-[775px] p-10 rounded-[24px] overflow-hidden;
        }
    }
}