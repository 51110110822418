.card {

    &-header {
        @apply absolute left-4 w-[250px] lg:w-[325px] text-center text-white;
        top: -21px;

        @include media-breakpoint-up(lg) {
            top: -27px;
        }

        > h2 {
            @apply py-4 lg:py-7 text-1xl lg:text-2xl italic;
        }
    }

    &-content {

        .row {

            &-content {

                &__title {

                    small {
                        @apply text-xs italic;
                    }
                }

                &__list {

                    &-item {
                        @apply flex;

                        &::before {
                            content: '';
                            @apply relative inline-block w-4 h-4 mt-1 mr-3 bg-no-repeat bg-contain;
                            background-image: url('../images/checkmark.png');
                        }
                    }
                }
            }

            &-image {
                @apply lg:mt-6;
            }
        }
    }
}