.footer {

    &::before {
        @apply absolute w-full;
        background-color: #FFCC33;
        mask-image: url(../images/Mask-group-footer.png);
        mask-position: center top;
        mask-size: cover;
        mask-repeat: no-repeat;
        content: '';
        height: 80px;
        margin-top: -80px;

        @include media-breakpoint-up(lg) {
            height: 160px;
            margin-top: -160px;
        }
    }

    &-wrapper {
        height: 500px;

        @include media-breakpoint-up(lg) {
            height: 560px;
        }
    }

    &-absolute {
        @apply bg-white inline-flex items-center justify-center w-14 h-14 rounded-full;
    }

    &-content {

        .social {

            &-item {
                
                &__link {
                    @apply bg-secondary w-9 h-9 inline-flex items-center justify-center rounded-full overflow-hidden;
                }
            }
        }

        .copy {

            @include media-breakpoint-down(md) {
                @apply pb-24;
            }

            &-dialog {

                &__link {

                    @include media-breakpoint-down(lg) {
                        @apply mt-8;
                    }
                }
            }
        }
    }
}