.hamburger {
    @apply relative block lg:hidden w-10 h-10;

    &-push {
        @apply flex items-center w-full h-full transition ease-in-out;

        > span {
            @apply bg-primary absolute top-5 block w-10 h-1 transition ease-in-out;

            &::before, &::after {
                content: '';
                @apply bg-primary absolute w-10 h-1 transition ease-in-out;
            }

            &::before {
                @apply -top-[10px];
            }

            &::after {
                @apply top-[10px];
            }
        }

        &.show {

            > span {
                @apply bg-transparent;
                
                &::before {
                    transform: rotateZ(45deg) scaleX(1) translate(8px, 8px);
                }

                &::after {
                    transform: rotateZ(-45deg) scaleX(1) translate(7px, -7px);
                }
            }
        }
    }

    &-menu {
        top: calc(var(--navbar-height) + 48px);
        height: calc(100vh - var(--navbar-height) + 48px);
        box-shadow: 0 16px 16px rgba($color: #000000, $alpha: .1);
        transform: translate3d(0, -100%, 0);

        &.show {
            @apply visible opacity-100;
            transform: translate3d(0, 0, 0);
        }
    }
}