.pagination {

    &-item {

        &__button {
            @apply bg-gray w-10 h-10 font-semi;

            &.current {
                @apply bg-grayDark text-white;
            }
        }
    }
}