.step {

    &-body {

        &__header {

            &-list {

                .item {
                    @apply py-2 px-3 bg-graySemi bg-opacity-60 border border-grayLight rounded-rg prose-headings:flex-1 lg:prose-headings:pr-4;

                    @include media-breakpoint-down(sm) {
                        @apply prose-headings:pr-1 prose-headings:text-sm;
                    }

                    @include media-breakpoint-up(md) {
                        @apply px-6 py-4;
                    }

                    &-icon {
                        @apply ml-auto opacity-0;
                    }

                    &.active, &.done {
                        @apply bg-secondary text-white border-primary;

                        .item {

                            &-icon {
                                @apply opacity-100;
                            }
                        }
                    }
                }
            }
        }

        &__content {

            &-block {

                .content {

                    &-payment {

                        &__group {

                            .item {
                                @apply p-3 bg-primary border border-primary rounded-sm cursor-pointer overflow-hidden transition ease-in-out;

                                &:hover {
                                    @apply bg-secondary text-white;

                                    .item {

                                        &-icon {
                                            @apply border-white;

                                            svg {
                                                @apply fill-white;
                                            }
                                        }
                                    }
                                }

                                &-icon {
                                    @apply inline-flex items-center justify-center w-4 h-4 border border-black rounded-full;
                                }
                            }
                        }
                    }

                    &-info {

                        &__item {

                            &-title {

                                @include media-breakpoint-up(md) {
                                    width: 150px;

                                    &::after {
                                        content: ':';
                                        @apply absolute top-0 right-0;
                                    }
                                }
                            }

                            &-value {
                                @apply flex-1;

                                @include media-breakpoint-up(md) {
                                    @apply pl-4;
                                }
                                
                                p {
                                    text-shadow: 0 3px 6px rgba($color: #000000, $alpha: .3);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}