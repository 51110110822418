.form {

    &-file {
        @apply relative;

        input[type="file"] {
            @apply absolute top-0 left-0 opacity-0 invisible;
        }

        label {
            @apply relative block lg:py-10 p-8 text-center border border-dashed border-graySemi rounded-rg cursor-pointer overflow-hidden;

            .icon {
                @apply text-black opacity-60 italic;

                &-image {
                    @apply mb-2;

                    img {
                        @apply h-8 mx-auto;
                    }
                }
            }

            .info {
                @apply text-black opacity-60 italic;
            }
        }
    }
}