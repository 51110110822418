.navbar {
    background-color: var(--navbar-background);
    color: var(--navbar-text-color);
    transition: ease-in-out .25s, height .25s;
    box-shadow: 0px 8px 24px rgba($color: #000000, $alpha: .1);

    &--fixed {
        @apply shadow-lg;
    }

    &-navigation {
        height: var(--navbar-height);
    }

    &-menu {
        @apply hidden lg:flex;

        &__list {

            .link {
                @apply relative;

                &.active {
                    @apply text-secondary;

                    &::before {
                        @apply opacity-100;
                    }
                }
                
                &:hover {
                    @apply text-secondary;

                    svg {
                        @apply fill-secondary;
                    }
                }

                &::before {
                    @apply bg-secondary absolute -bottom-3 w-2 h-2 left-1/2 -translate-x-1/2 rounded-full overflow-hidden opacity-0;
                    content: '';
                }
            }
        }
    }

    &-social {

        a {
            @apply bg-white w-5 h-5 inline-flex items-center justify-center rounded-full overflow-hidden;
        }
    }
}