.contact {

    &-social {

        &__body {

            .item {
                @apply px-4 py-8 bg-white bg-opacity-60;
                backdrop-filter: blur(30px);

                @include media-breakpoint-up(lg) {
                    @apply py-12 px-14;
                }
            }
        }
    }

    &-map {

        &__image {

            @include media-breakpoint-up(lg) {
                height: 400px;
            }

            &::before, &::after {
                content: '';
                @apply block;
            }

            &::before {
                padding-top: 50%;
            }

            &::after {
                @apply clear-both;
            }

            > * {
                @apply absolute top-0 left-0 h-full w-full;
            }
        }

        &__body {

            @include media-breakpoint-down(lg) {
                @apply mt-8;
            }

            @include media-breakpoint-up(lg) {
                margin-top: -15%;
            }

            .social {

                &-content {

                    &__item {

                        &:hover {

                            .icon {
                                @apply bg-secondary;
                            }
                        }

                        .icon {
                            @apply bg-primary w-7 h-7 inline-flex items-center justify-center rounded-full;
                        }
                    }
                }
            }
        }
    }

    &-form {

        &__body {
            @apply p-6;

            @include media-breakpoint-up(lg) {
                @apply py-14 px-[124px];
            }
        }
    }

    &-actions {

        @include media-breakpoint-up(lg) {
            height: 550px;
        }

        &__body {

            &-content {

                @include media-breakpoint-down(lg) {
                    @apply py-8;
                }

                @include media-breakpoint-up(lg) {
                    @apply w-1/2 pr-4;
                }
    
                &-tag {
    
                    @include media-breakpoint-up(lg) {
                        @apply prose-headings:text-4xl;
                    }
                }
            }
        }

        &__image {
    
            @include media-breakpoint-up(lg) {
                @apply right-0 top-0 bottom-0;
            }
        }
    }

    &-success {

        &__body {

            &-content {
                @apply p-8 bg-white bg-opacity-60 rounded-[24px];
                backdrop-filter: blur(30px);
                box-shadow: 4px 4px 16px rgba($color: #fff, $alpha: .3);

                .image {
                    margin-top: -20%;

                    @include media-breakpoint-up(md) {
                        margin-top: -10%;
                    }
                }

                .text {
                    
                    &-title {
                        @apply prose-headings:text-4xl prose-headings:font-special prose-headings:font-normal prose-headings:text-secondary;
                        
                        @include media-breakpoint-up(lg) {
                            @apply prose-headings:text-12xl;
                        }
                    }
                }
            }
        }
    }
}