.post {

    &-detail {
        @apply prose-headings:leading-tight prose-headings:mb-2 prose-p:mb-4 prose-a:text-black prose-a:font-bold prose-ul:mb-4 prose-ol:mb-4 prose-ul:pl-10 prose-ol:pl-10 prose-ul:list-disc prose-ol:list-decimal;
        line-height: 1.75;

        p {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }

        ol, ul {

            p {
                margin-bottom: 0 #{!important};
            }
        }

        ul {
            
            ul {
                @apply mb-0;
                list-style-type: circle;
            }
        }
    }
}