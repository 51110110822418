.form {

    &-input, &-textarea, &-select {
        @apply text-black border-graySemi w-full px-4 py-3 rounded-sm transition ease-in-out ring-0 placeholder:text-graySemi placeholder:italic focus:border-secondary #{!important};

        &[disabled] {
            @apply bg-gray;
        }
    }

    &-validate {

        .form {

            &-input, &-textarea, &-select {
                @apply border-secondary #{!important};
            }
        }

        &__feedback {
            @apply mt-1 text-secondary;

            small {
                @apply text-sm;
            }
        }
    }

    &-captcha {
        
        @include media-breakpoint-down(lg) {
            transform: scale(.75);
        }
    }

    &-caption {
        @apply border-b border-b-transparent;

        &:hover {
            @apply border-b-secondary;
        }
    }

    &-choosen {

        input[type='radio'] {
            @apply absolute block top-0 left-0 right-0 appearance-none;
            border: none;
            outline: none;
        }

        &--package {

            input[type='radio'] {

                &:checked {

                    ~ label {

                        .form {

                            &-choosen {

                                &__label {

                                    .dots {
                                        @apply bg-secondary;

                                        &::after {
                                            @apply opacity-100;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ~ label {
                    @apply bg-primary block mb-0 rounded-rg overflow-hidden cursor-pointer;

                    .form {

                        &-choosen {

                            &__label {

                                .dots {
                                    @apply relative bg-white w-6 h-6 border-[2px] border-secondary rounded-full overflow-hidden;

                                    &::after {
                                        @apply absolute border-2 border-t-0 border-r-0 border-solid border-white opacity-0;
                                        content: '';
                                        height: 6px;
                                        width: 12px;
                                        top: 5px;
                                        left: 4px;
                                        border-top: none;
                                        border-right: none;
                                        transform: rotate(-45deg);
                                    }
                                }

                                &-content {

                                    .left {
                                        
                                        @include media-breakpoint-down(md) {
                                            @apply pb-4 border-b border-b-white;
                                        }
                                        
                                        @include media-breakpoint-up(md) {
                                            @apply pr-5 border-r border-r-white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}