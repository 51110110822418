.lost {

    &-success {

        &__body {
            max-width: calc(100% - 16px);

            @include media-breakpoint-up(lg) {
                @apply max-w-[780px];
            }

            &-content {

                .text {

                    @include media-breakpoint-down(xs) {
                        @apply mt-4;
                    }

                    @include media-breakpoint-up(sm) {
                        @apply absolute bottom-8 right-0 left-0;
                    }

                    @include media-breakpoint-only(sm) {
                        @apply bottom-3;
                    }

                    @include media-breakpoint-up(lg) {
                        @apply bottom-10;
                    }
                }
            }
        }
    }
}