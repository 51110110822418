.modal {

    &-dialog {

        .modal--serial &, .modal--channel & {

            @include media-breakpoint-up(lg) {
                max-width: 715px;
            }
        }
    }

    &-content {

        .modal--serial &, .modal--channel & {
            @apply rounded-rg;
        }
    }
    
    &-title {

        .modal--serial & {
            @apply p-6 pb-0;

            @include media-breakpoint-up(lg) {
                @apply p-10;
            }
        }

        &__icon {
            @apply z-10;
        }
    }

    &-body {

        .modal--serial & {
            @apply p-6;

            @include media-breakpoint-up(lg) {
                @apply p-10;
            }

            &__content {
                @apply overflow-y-scroll;
                height: 420px;

                &::-webkit-scrollbar {
                    @apply w-2 h-3 rounded-full;
                }
                
                &::-webkit-scrollbar-thumb {
                    @apply bg-graySemi rounded-full;
                }
            }
        }

        .modal--channel & {
            @apply p-6;

            @include media-breakpoint-up(lg) {
                @apply p-10;
            }

            &__content {

                .post {

                    &-title {

                        &__content {
                            @apply uppercase;
                        }
                    }
                }
            }
        }
    }
}