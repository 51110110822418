.package {

    &-body {

        &__header {

            @include media-breakpoint-down(lg) {
                @apply flex-wrap -m-2;

                > div {
                    @apply p-2;
                }
            }

            @include media-breakpoint-up(lg) {
                @apply -mx-5;

                > div {
                    @apply px-5;
                }
            }

            .item {
                @apply py-3 px-5 font-bold bg-primary border-[2px] border-primary rounded-rg overflow-hidden;

                &.active {
                    @apply bg-secondary text-white;
                }
            }
        }

        &__payment, &__channel {

            &-list {

                .item {

                    &-image {
                        @apply flex items-center justify-center mb-3 h-18 bg-white rounded-sm overflow-hidden prose-img:mx-auto prose-img:max-h-8;

                        @include media-breakpoint-down(lg) {
                            @apply prose-img:max-w-[98px];
                        }

                        @include media-breakpoint-up(lg) {
                            @apply prose-img:max-w-[180px];
                        }
                    }
                }
            }
        }
    }
}