$slick-loader-path: "/assets/js/vendor/slick/" !default;
$slick-font-path: "/assets/js/vendor/slick/fonts/" !default;

@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick {
    @apply opacity-0;

    &.slick-initialized {
        @apply opacity-100;
    }

    &--program-thumb {
        @apply absolute -bottom-19 right-0 left-0 z-10;
    }

    &-dotted {

        &.slick-slider {
            @apply mb-0;
        }
    }

    &-list {

        .slick--channel & {
            overflow: unset;
        }
    }

    &-slide {

        .slick--home & {

            .item {
                @apply lg:aspect-[16/6];
    
                img {
                    @apply w-full h-full object-cover;
                }
            }
        }

        .slick--channel & {
            @apply mx-6;

            .item {

                img {
                    @apply mx-auto h-8 lg:h-25;
                }
            }
        }

        .slick--program-header &, .slick--program-thumb & {

            img {
                @apply w-full h-full object-top object-cover;
            }
        }
        
        .slick--program-header & {

            .item {
                @apply overflow-hidden;
            }
        }

        .slick--program-thumb & {
            @apply mx-1 lg:mx-4;

            &.slick-current {

                .item {
                    
                    &-image {
                        @apply border-secondary;
                    }
                }
            }

            .item {

                &-image {
                    @apply aspect-video border-4 border-transparent;
                }
            }
        }
    }

    &-prev, &-next {
        @apply z-30 before:hidden;

        &.slick-disabled {
            @apply opacity-50;
        }
        
        .slick--home &, .slick--program-header & {
            @apply top-1/2 bg-white bg-opacity-10 w-10 h-10 lg:w-16 lg:h-16 rounded-full;

            &:hover {
                @apply bg-secondary;
            }
        }
    }

    &-prev {
        
        .slick--home &, .slick--program-header & {
            @apply left-2 lg:left-5;
        }
    }

    &-next {
        
        .slick--home &, .slick--program-header & {
            @apply right-2 lg:right-5;
        }
    }
    
    &-dots {
        @apply bottom-8;

        > li {

            .slick--home & {
                @apply w-4;
                height: 3px;

                &.slick-active {
                    @apply w-8;
                    height: 6px;

                    button {
                        @apply bg-primary;
                    }
                }

                button {
                    @apply w-full h-full p-0 bg-white rounded-rg;
                }
            }

            button {
                @apply before:hidden;
            }
        }
    }
}