.float {

    @include media-breakpoint-up(lg) {
        @apply bottom-16 #{!important};
    }

    &-body {

        &__link {
            @apply p-1;
        }
    }
}