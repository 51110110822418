.jumbotron {

    @include media-breakpoint-down(lg) {
        @apply bg-primary;
    }
    
    @include media-breakpoint-up(md) {
        height: 300px;
    }

    &-body {

        &__content {

            @include media-breakpoint-down(md) {
                @apply pt-4 pb-8;
            }

            @include media-breakpoint-up(md) {
                @apply w-1/2 pr-4;
            }

            &-tag {
                @apply prose-p:mb-2 prose-p:uppercase prose-p:font-bold prose-p:tracking-[4px];

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-6xl;
                }
            }
        }
    }

    &-image {
        // @apply opacity-60;

        @include media-breakpoint-down(md) {
            @apply relative;
            
            // &::before {
            //     content: '';
            //     @apply absolute top-0 left-0 h-full w-full;
            //     background: linear-gradient(0,#FFCC33 0%,rgba(196,235,218,0));
            // }
        }

        // @include media-breakpoint-only(md) {
            
        //     &::before {
        //         content: '';
        //         @apply absolute top-0 left-0 h-full w-full;
        //         background: linear-gradient(90deg,#FFCC33 0%,rgba(196,235,218,0));
        //     }
        // }

        @include media-breakpoint-up(md) {
            @apply absolute right-0 top-0 bottom-0;
        }
    }
}