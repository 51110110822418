.intro {

    &-dialog {
        @apply py-18 lg:py-25;
    }

    &-content {

        .content {

            h2 {

                @include media-breakpoint-down(lg) {
                    @apply text-2xl;
                }
            }
        }
    }
}

.features {

    &-content {

        .title {
            @apply lg:w-2/5;

            span {
                @apply font-bold;
            }

            @include media-breakpoint-down(lg) {
                @apply mb-8;
            }
        }

        .content {
            @apply flex-1;
        }
    }
}

.hero {

    &-package {

        &__body {

            .title {
                @apply text-white;

                span {
                    @apply font-bold;
                }
            }
        }
    }

    &-contact {

        &__body {

            .title {
                @apply text-white;

                span {
                    @apply font-bold;
                }

                &-actions {

                    &__item {

                        .icon {
                            @apply flex items-center justify-center w-14 h-14 transition ease-in-out;
                        }

                        .value {

                            &-tag {
                                @apply mb-1;
                            }

                            &-link {
                                @apply font-bold lg:text-2xl;
                            }
                        }
                        
                        &:hover {

                            .icon {
                                @apply bg-secondary;
                            }
                        }
                    }
                }
            }

            .field {

                &__group {
                    @apply p-6 lg:px-15 lg:py-14 bg-white rounded-rg overflow-hidden;
                }
            }
        }
    }

    &-faq {

        &__body {

            > .title {
                @apply text-white;

                span {
                    @apply font-bold;
                }
            }
        }
    }
}