.main {

    &-content {
        margin-top: calc(var(--navbar-height) + 48px);

        &__wrapper {
            @apply bg-secondary;

            &::before {
                @apply absolute top-0 left-0 right-0 h-full bg-top bg-cover bg-no-repeat;
                content: '';
                background-image: url(../images/MaskGroup30.png);
            }
        }
    }
}