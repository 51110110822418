.dropdown {

    &-toggle {

        .dropdown--navbar & {
            @apply inline-flex items-center;

            &::after {
                @apply hidden;
            }

            &.show {
                @apply text-secondary;

                .arrow {
                    @apply -rotate-180;

                    svg {
                        @apply fill-secondary;
                    }
                }
            }
        }
    }

    &-menu {
        @apply px-6 py-4;
        
        &[data-bs-popper] {
            @apply mt-0;
        }

        .dropdown--navbar & {
            @apply rounded-sm min-w-max;
            box-shadow: 0 16px 16px rgba($color: #000000, $alpha: .15);
        }
    }
}